module.exports = [{
      plugin: require('../node_modules/@yumgmbh/gatsby-theme-yum-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-plugin-sb-nav/gatsby-browser.js'),
      options: {"plugins":[],"storyblokToken":"YBhbZUeYne0mBIgGGbi8Ogtt","version":"published","by_slugs":"global/navigations/*"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#3c6cd3","display":"minimal-ui","icon":"src/images/bme-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c0ad31b5982026ab484e2a3b101a212f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"43","matomoUrl":"https://piwik2.yum.de","siteUrl":"https://www.bme.de","dev":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bme.de","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
