import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  fallbackLng: "de",
  resources: {
    de: {
      translations: require("./translations.de.json"),
    },
    en: {
      translations: require("./translations.en.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18n.languages = ["de", "en", "fr", "nl"]

export default i18n
