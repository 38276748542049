import React from "react"
import "@yumgmbh/gatsby-theme-yum-components/src/scss/style.scss"
import "@fortawesome/fontawesome-pro/css/solid.min.css"
import { I18nContext } from "./src/context/I18nContext"
import AuthContextProvider from "./src/context/AuthContext"
require("typeface-inter")

const wrapRootElement = ({ element }) => {
  return <AuthContextProvider>{element}</AuthContextProvider>
}

const wrapPageElement = ({ element, props }) => {
  return <I18nContext pageContext={props.pageContext}>{element}</I18nContext>
}

export { wrapPageElement, wrapRootElement }

export const onRouteUpdate = ({ location }) => {
  if (!Array.isArray(window.gtag)) {
    return null
  }

  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView)
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32)
  }
}
