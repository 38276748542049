exports.components = {
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-basic-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/basic.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-basic-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-extended-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/extended.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-extended-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-index-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/index.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-index-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-media-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/media.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-media-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/pages/storyblok-preview.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demo-1-jsx": () => import("./../../../src/pages/demo/1.jsx" /* webpackChunkName: "component---src-pages-demo-1-jsx" */),
  "component---src-pages-demo-2-jsx": () => import("./../../../src/pages/demo/2.jsx" /* webpackChunkName: "component---src-pages-demo-2-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-demo-workspace-jsx": () => import("./../../../src/pages/demo/workspace.jsx" /* webpackChunkName: "component---src-pages-demo-workspace-jsx" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-shared-templates-news-overview-page-template-js": () => import("./../../../src/shared/templates/newsOverviewPageTemplate.js" /* webpackChunkName: "component---src-shared-templates-news-overview-page-template-js" */)
}

