import React, { createContext, useState, useEffect } from "react"
import Cookies from "universal-cookie"
import jwt_decode from "jwt-decode"

export const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
  const [role, setRole] = useState(null)

  useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get("nf_jwt")
    if (token && token !== "undefined") {
      const decoded = jwt_decode(token)
      setRole(decoded.app_metadata.authorization.roles)
    } else {
      setRole(["none"])
    }

    return () => {
      setRole(null)
    }
  }, [])

  return (
    <AuthContext.Provider value={{ role }}>{children}</AuthContext.Provider>
  )
}

export default AuthContextProvider
